@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=PT+Sans&family=Roboto&display=swap');


.download
{
    display:none !important;    
}

.print
{
    display:none !important;
}
@media only print {
  body { display:none !important; } 
}

:root {

  --excel_grad_one: #414141;
  --excel_grad_two: #040404;
  --excel_grad_blk: #000000;

  --theme-color-1: #009BCE;

    --libspacing: 2rem;
}

html, body {
  font-family: 'Open Sans', sans-serif;
  font-family: 'PT Sans', sans-serif;
  font-family: 'Roboto', sans-serif;
  margin: 0; 
  height: 100%; 
  /* overflow: hidden; */
}

  canvas{
    /* width: 100%;
  height: 100%; */
	/* border:1px solid #cccccc; */
	/* disable antialiasing */
	image-rendering: optimizeSpeed;             /* Older versions of FF          */
	image-rendering: -moz-crisp-edges;          /* FF 6.0+                       */
	image-rendering: -webkit-optimize-contrast; /* Safari                        */
	image-rendering: -o-crisp-edges;            /* OS X & Windows Opera (12.02+) */
	image-rendering: pixelated;                 /* Awesome future-browsers       */
	-ms-interpolation-mode: nearest-neighbor;   /* IE                            */
  pointer-events:none;

  -webkit-user-select: none;  /* Chrome, Safari and Opera */
  -moz-user-select: none;     /* Firefox */
  -ms-user-select: none;      /* IE/Edge */
  user-select: none; 
}


pre {
  white-space: pre-wrap;       /* CSS 3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  overflow-x: auto;
  overflow: visible;
  text-align: justify;
}

.excel_bg_global{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(var(--excel_grad_one), var(--excel_grad_two));
}

.excel_div_main{
    width: 100vw;
    height: 100vh;
    margin: 0 auto; 
    position: relative;
    overflow: hidden;
    align-items: center;
    /* pointer-events:auto; */
    
}
/* Create the first layer */
.excel_div_main:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(var(--excel_grad_one), var(--excel_grad_two));
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  pointer-events:none;
}

/* Create the second layer */
.excel_div_main:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(var(--excel_grad_one), var(--excel_grad_blk) 30%);
  opacity: 0;
  transition: opacity 2s ease-in-out;
  pointer-events:none;
}

/* Add the gradient_off class to change the opacity of the layers */
.excel_div_main.gradient_off:before {
  opacity: 0;
  pointer-events:none;
}
.excel_div_main.gradient_off:after {
  opacity: 1;
  pointer-events:none;
}


.excel_center_vert{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);

  height: 100%;
  width: 100%;
  display:flex;
  z-index:1;
  pointer-events: none;
}

.excel_inner {
  position: absolute; 
  /* top: 50%;
  left: 50%;
  -ms-transform:translate(-50%, -50%);
  transform: translate(-50%, -50%); */

  height: 100vh;
  display: flex;

 
align-items: center;
justify-content: center;
  
}

.excel_div_canvas{

    display:flex;

    padding:0; 
    /* This was all */
    /* pointer-events:none;  */

    object-fit: contain;
    object-position: center;

    max-width: 100%;
   max-height: 100%;
   width: auto;
   height: auto;

    
}

.excel_div_canvas canvas {
  width: 100%;
  height: 100%;
  object-fit:contain;
}

.excel_canvas_container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}


.excel_test_buttons{
    z-index:3;
}
.working_indicator {
    position: absolute; 
  left: 0;
  -ms-transform:translate(100%, -150%);
  transform: translate(100%, -150%);
  opacity: 75%;
  z-index: 3;
  pointer-events:none;
  }
  .working_indicator_bottom {
    position: absolute; 
    width: 100vw;
    top: 100%;
    left:0;
    -ms-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
    z-index: 9999;
    pointer-events:none;
  }

  .excel_menu_top{
    pointer-events: auto;
    cursor: not-allowed;
  }


  .excel_protective_hide{
    display:"none"
  }
  .excel_protective_show, .excel_protective_show_fade{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height : 100vh;
    background-color: black;
    z-index: 99999;
    align-items: 'center';
    justify-content: 'center'; 

    
  }

  .excel_protective_show_fade{
    -webkit-animation: .8s ease 0s normal forwards 1 excel_key_warning_fadein;
    animation: .8s ease 0s normal forwards 1 excel_key_warning_fadein;
  }
  @keyframes excel_key_warning_fadein{
    0% { opacity:0; }
    75% { opacity:0; }
    100% { opacity:1; }
}

@-webkit-keyframes excel_key_warning_fadein{
    0% { opacity:0; }
    75% { opacity:0; }
    100% { opacity:1; }
}

@keyframes fadeIn{
  0% { opacity:0; }
  100% { opacity:1; }
}

@-webkit-keyframes fadeIn{
  0% { opacity:0; }
  100% { opacity:1; }
}

@keyframes fadeOut{
  0% { opacity:1; }
  100% { opacity:0; }
}

@-webkit-keyframes fadeOut{
  0% { opacity:1; }
  100% { opacity:0; }
}

.excel_settings_icons{
  transform: scale(.8);
}


.excel_svg_line_text{
  scale: 200%;
  margin: 4em;
  /* animation:bounce 1s ease infinite; */
}
.excel_svg_line{
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
  opacity:50%;
  scale: 50%;
  /* animation:bounce 1s ease infinite; */
}
.excel_book_logo{
  fill: url(#header-shape-gradient) #fff;
}
.excel_line {
  stroke: #414141;
  stroke-width: 1px;
  
}
.excel_line-alt {
  stroke-dasharray: 10;
  stroke-width: 2px;
  stroke: #1976d2;
  animation: excel_dash .8s linear infinite;
}

@keyframes excel_dash {
  from {
    stroke-dashoffset: 120;
   
  }
  to {
    stroke-dashoffset: 0;
    
  }
}

#header-shape-gradient {
  --color-stop: #2d79c6;
  --color-bot: #103c55;
}


@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px); } }
@-moz-keyframes bounce {
  0%, 100% {
    -moz-transform: translateY(0); }
  40% {
    -moz-transform: translateY(-10px); }}
@keyframes bounce {
  0%, 100%{
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px); }
  }

  .excel_fade_in{
    -webkit-animation: .5s ease 0s normal forwards 1 fadeIn;
    animation: .5s ease 0s normal forwards 1 fadeIn;
  }

  .excel_fade_out{
    -webkit-animation: .5s ease 0s normal forwards 1 fadeOut;
    animation: .5s ease 0s normal forwards 1 fadeOut;
  }

.excel_mask_logo{
  position:absolute;
  bottom:0;
  right:0;
  transform: translate(-40px, -20px);
  z-index: 9999;
  -webkit-animation: .5s ease 0s normal forwards 1 fadeIn;
    animation: .5s ease 0s normal forwards 1 fadeIn;
}

.excel_copyright{
  position: relative;
  display: flex;
  margin: 0;
  max-width: 80vw;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.excel_copyright_logo{
  margin: 0;
  padding: 0 4px;
  max-width: 20px;
  max-height: 20px;
  display: block;
  fill: #fff;
}

.link-color a {
  color: white !important;
}



/*******

HOME SPECIFIC

********/

.excelsior-home {
  overflow: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.excelsior-home .comic {
  text-align: center;
}

.excelsior-home h3 {
  font-weight: bold;
}

.excelsior-home .MuiButton-root {
  margin-top: 5%;
}

.excelsior-home .MuiToolbar-root {
  justify-content: center;
  /* margin-bottom: 20px; */
}

.excelsior-home .MuiTypography-caption {
  color: rgba(0, 0, 0, 0.54);
}

.excelsior-home .excelsior-section {
  margin-top: 2%;
  margin-bottom: 2%;
  flex: 1; /* Add this */
}

.excelsior-home .or-line {
  display: flex;
  align-items: center;
}

.excelsior-home .or-line .line {
  flex-grow: 1;
  height: .1px;
  background-color: #454545;
}

.excelsior-home .or-line .or-text {
  margin: 0 1%;
  font-weight: bold;
}


/*******

BOOK ITEMS

********/

.comic-cover {
  max-width: 18vw;
  /* border: 2px solid black; */
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;
}


.comic-cover-container::after  {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0,0,0, .5), rgba(0,0,0, .5), transparent, transparent, transparent, transparent);

  pointer-events: none;
}

.coming-soon-banner-container {
  position: relative; /* Position relative to make sure the banner stays inside this div */
  background-size: cover; /* Adjust the background size as needed */
  color: white; /* Text color for the Typography element */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  pointer-events: none;
}

.coming-soon-text {
  background-color: rgba(27, 27, 41, 0.911); /* Add some transparency to the background color */
  padding: 1rem 2rem; /* Adjust the padding as needed */
  border-radius: 5px; /* Add some rounded corners to the banner */
  pointer-events: none;
}

.sold-out-text {
  background-color: rgba(214,0,28,.7); /* Add some transparency to the background color */
  padding: 1rem 2rem; /* Adjust the padding as needed */
  border-radius: 5px; /* Add some rounded corners to the banner */
}

/*******

DETAILS

********/

.excelsior-details {
  display: flex; 
  flex-direction: column; 
  width: 100vw;
  height: 100vh;
  position: relative;
  align-items: center;
  justify-content: center;

 
}

.excelsior-details-background{
  position: absolute;
  top: 0;
  right: -3rem;
  bottom: 0;
  left: -3rem;
  /* Apply the blur and blend mode */
  background-size: cover;
  background-position: center;
  filter: blur(30px);
  mix-blend-mode: screen;
  opacity: .75;
}

.excelsior-details-scroll {
  overflow-y: auto;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  max-width: 2000px;
  position: absolute;
}

/*******

LIBRARY

********/

.excelsior-library {
  display: flex; /* Flexbox layout */
  flex-direction: column; /* Stack the AppBar and the content vertically */
  width: 100vw;
  height: 100vh;
  position: relative;

}

.excelsior-lib-scroll {
  overflow-y: auto;
  flex-grow: 1;
  /* height: calc(100% - 56px - var(--libspacing) - var(--libspacing));  */
  height: 100%;
  width: 100%;
  position: absolute;
}

.excelsior-library .MuiToolbar-root {
  justify-content: flex-start;
  padding: 0 var(--libspacing);
}

.excelsior-library .MuiAppBar-root {
  margin-bottom: var(--libspacing);
}

.excelsior-library .MuiStack-root {
  margin: 0;
  padding: 0;
}



/********

TRANSITIONS


*********/

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}


/*******

RESPONSIVE

*******/

@media (max-height: 900px) {

  .excelsior-home {
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    overflow-y: scroll;
    height: 100%; 
    width: 100%;
    min-height: 100%;
    min-width: 100vw;
    padding: 1%;
    border-radius: 0;
    box-sizing: border-box;
    position:absolute;
  }
  
}

@media (min-width: 1440px) {
    .excelsior-home-container {
      align-self: center;
      width: 100%;
      max-width: 2000px;
  }
 
}

@media (max-width: 1200px) {

  html, body {
    height: 100%;
  }

  /* .excel_div_main{
    height: auto;
  } */
  .comic-cover {
    max-width: 100%;
  }

  .excel_center_vert{
    transform: translate(-50%,0%);
  }

  .excel_inner {
    height: auto;
  }

  .excelsior-home {
    overflow-y: auto;
    border-radius: 0;
    padding-left: 5%;
    padding-right: 5%;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    min-height: 100%;
    height: 100%; 
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
  }


  .excelsior-home .or-line {
    padding: 10%;
  }

  .excelsior-home .comic-cover {
    width: 100%;
  }

  .file-upload-dropzone {
    display: none;
  }

  .excelsior-library {
    overflow-x: scroll;
    display: flex; 
    width: 100vw;
    position: relative;
  }

  .excelsior-library .MuiToolbar-root {
    justify-content: flex-start;
    padding: 0 0 0 10%;
    margin: 0;
  }
  
  .excelsior-library .MuiAppBar-root {
    margin-bottom: var(--libspacing);
    top: 0 !important;
  }

}
